import React from "react";
import { memo, useEffect, useState } from "react";
import classes from "./cardSort.module.scss";
import { FormattedMessage } from "react-intl";
import cn from "classnames";

export const BoxDragPreview = memo(function BoxDragPreview({ item }) {
  const [tickTock, setTickTock] = useState(false);
  const { name, description, description_id, title, title_id } = item;
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500);
      return () => clearInterval(interval);
    },
    [tickTock]
  );
  return (
    <div
      className={cn(classes.Question, classes.previewQuestionText)}
      style={{ background: "#F6F7FA", transform: "rotate(-10deg)" }}
    >
      <div
        className={cn(classes.TitleDescription, classes.pt5)}
        style={{ color: "#28353C" }}
      >
        <b>
          <FormattedMessage defaultMessage={title} id={title_id} />
        </b>
      </div>
      <div
        className={cn(classes.QuestionDescription, classes.p5)}
        style={{ color: "#28353C" }}
      >
        <FormattedMessage defaultMessage={description} id={description_id} />
      </div>
      <div className={classes.DotsContainerPreview}>
        <span className={classes.dotsPreview}>...</span>
        <span className={classes.dotsPreview}>...</span>
      </div>
    </div>
  );
});
