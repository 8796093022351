import React, { useEffect } from "react";
import classes from "./allchapterscomplete.module.scss";

import { Link } from "react-router-dom";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Heading from "components/UI/heading";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectProgress } from "store/progress/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useLocate, saveProgress } from "utils/hooks";
import { makeSelectSelectedInduction } from "store/induction/selector";
import Lottie from "react-lottie";

import CelebrateAnimation from "assets/animations/Mercury_celebration-once.json";

const AllChaptersComplete = ({
  setSection,
  getSectionTimeReq,
  selectedInduction,
  history,
  isPreview = false,
  pageData,
}) => {
  var { current, previous, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

  if (isPreview) current = pageData;

  useEffect(() => {
    if (!isPreview) {
      saveProgress(selectedInduction, history.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (isPreview) {
      current = pageData;
    }
  }, [pageData]);

  return (
    <>
      <div className={classes.AllChaptersCompleteContainer}>
        <Heading
          backArrow={false}
          title={current?.title}
          titleId={isPreview ? "-" : current?.title_id}
          description={current?.data?.subtitle}
          descriptionId={current?.data?.subtitle_id}
        />
        &nbsp;
        <div className={classes.animationWrapper}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: CelebrateAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
                progressiveLoad: true,
              },
            }}
            width={"40%"}
            isClickToPauseDisabled={true}
            isPaused={false}
            title={"animation"}
            ariaLabel={"animation"}
          />
        </div>
        <div className={classes.ContentContainer}>
          {current?.data?.content && (
            <p>
              <FormattedHTMLMessage
                defaultMessage={current?.data?.content}
                id={
                  isPreview ? current?.data?.content : current?.data?.content_id
                }
              />
            </p>
          )}

          {current?.data?.subcontent &&
            current?.data?.subcontent.trim() != "" && (
              <div className={classes.SubContentWrapper}>
                <p>
                  <FormattedHTMLMessage
                    defaultMessage={current?.data?.subcontent}
                    id={
                      isPreview
                        ? current?.data?.subcontent
                        : current?.data?.subcontent_id
                    }
                  />
                </p>
              </div>
            )}
        </div>
      </div>

      {/* Logout button */}
      <Footer>
          <Link className={classes.Link} to={'/logout'}>
            <Button
              formatID={"navigation.logout"}
              id="logout-button"
              text="Logout"
              theme="blue"
              type="submit"
            />
          </Link>
        </Footer>

      {!isPreview && current?.data?.show_footer_button && (
        <Footer>
          <Link className={classes.Link} to={next?.url}>
            <Button
              formatID={"siterules.button"}
              id="apply-button"
              text="Next"
              theme="blue"
              type="submit"
            />
          </Link>
        </Footer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllChaptersComplete);
