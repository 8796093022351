import React, { useEffect, useState } from "react";
import Menu from "components/Supervisor/menu";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getInductionDetailReq,updateInductionReq } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectUsers,
  makeSelectInductionDetail
} from "store/supervisor/selector";

import {
  makeSelectLanguages,
} from "store/language/selector";

import { isNil } from "lodash";
import ReactLoading from "react-loading";
import classes from "./editor.module.scss";
import queryString from "query-string";

import { getComponent } from "../../../../components/UI/section/componentHandler";
import Input from "../../../../components/Supervisor/input";
import Select from "react-select";

import pageIcon from "./icons/page_icon.png";
import pageIconSelected from "./icons/induction_check.svg";
import pageItemHeaderIcon from "./icons/pageitem_header.png";
import IconComponent from "../../../../components/UI/IconComponent";
import { Header } from "components";

//Forms for different page types
import Prequalification from "./Forms/prequalification";
import Introduction from "./Forms/introduction";
import Video from "./Forms/video";
import AllChaptersCompleteForm from "./Forms/allChaptersComplete";
import InductionCompleteForm from "./Forms/inductionComplete";
import RulesForm from "./Forms/rules";
import ChaptersForm from "./Forms/chapters";


//pages for preview
import Introductory from "../../../Introductory";
import Welcome from "../../../Welcome";
import LookAround from "../../../LookAround";
import Induction from "../../../Induction";
import AllChaptersComplete from "../../../AllChaptersComplete";
import SiteRules from "../../../SiteRules";
import Complete from "../../../Complete";
import AssesmentPage from "../../../Assessment";
import PostLogin from "../../../PostLogin";


const InductionEditor = ({
  history,
  loading,
  induction,
  getInductions,
  updateInduction,
  languages
}) => {
  const id = queryString.parse(window.location.search).id;

  const [editData, setEditData] = useState(false);
  const [selectedPage, setSelectedPage] = useState({title: ''});
  const [selectedRule, setSelectedRule] = useState({title: ''});
  const [selectedSection, setSelectedSection] = useState({title: ''});
  const [languageOptions, setLanguageOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({code: 'en', name: "English"});
  const [translations, setTranslations] = useState({});


  useEffect(() => {
    if (languages?.length) {
      const dfList = languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);

      //set the translation for the selected language
      var newTransalation = {}
      for(var i = 0; i < languages.length; i++)
      {
          newTransalation[languages[i].code] = JSON.parse(languages[i]?.data);
      }

      setTranslations(newTransalation);

    }
  }, [languages]);


  useEffect(() => {
    getInductions({id:id});
  }, []);

  useEffect(() => {
    //console.log(induction);
  }, [induction]);

  function updateSelectedPage(item)
  {
     setSelectedPage(item);
     setSelectedRule({title: ''}); //reset
     setSelectedSection({title: ''}); //reset
     //console.log(item);
  }

  function updateSelectedRule(item)
  {
     setSelectedRule(item);
     setSelectedSection({title: ''}); //reset
     //console.log(item);
  }

  function updateSelectedSection(item)
  {
     setSelectedSection(item);
     //console.log(item);
  }  

  function updateTranslation(language,key,content)
  {
      var newTranslation = {...translations}
      newTranslation[language][key] = content;
      setTranslations(newTranslation);

      console.log('translations',newTranslation);
  }

  function handleSubmit()
  {
     //This is our gigantic induction save function so we will go step by step
     
    //update page data
    var data = { ...induction.data }
    var pageData = [...induction.data.pages]

    for(var i = 0; i < induction.data.pages.length; i++)
    {
        if(induction.data.pages[i].id == selectedPage.id)
        {
            //console.log('found id: '+ selectedPage.id);
            pageData[i] = {...selectedPage};
        }


        if(induction.data.pages[i].data.rules)
        {
          var ruleData = [...induction.data.pages[i].data.rules]
          for(var j = 0; j < induction.data.pages[i].data.rules.length; j++)
          {
              if(induction.data.pages[i].data.rules[j].id == selectedRule.id && selectedRule.id > 0)
              {
                  //console.log('found id: '+ selectedRule.id);
                  ruleData[j] = {...selectedRule};
              }
              
              if(induction.data.pages[i].data.rules[j]?.sections)
              {
                
                var updatedSections = [induction.data.pages[i].data.rules[j].sections];
                for(var k = 0; k < updatedSections.length; k++)
                {
                    if(induction.data.pages[i].data.rules[j].sections[k].header_id == selectedSection?.header_id)
                    {
                      updatedSections[k] = selectedSection;
                    }

                    ruleData[j] = {...ruleData[j],sections:updatedSections};

                }
              }

          }
          
          //console.log('ruleData', ruleData);
          //doesn't work for now
          var updatedData = {...pageData[i],data:{...pageData[i].data, rules: ruleData}}
          pageData[i] = {...updatedData};
          //pageData[i] = {...pageData[i], 'rules': ruleData};

        }
    }

    data.pages = pageData;


    console.log('data.pages',pageData);
    
    //DO NOT OPEN UNTIL YOU ARE SURE DATA IS ASSEMBLED CORRECTLY!
    //updateInduction({'id': id, 'data': JSON.stringify(data), 'translations': JSON.stringify(translations)})

  }
  
  //function that is called by the special forms
  function updatePageData(val)
  {
    console.log('update page data called',val);
    if(val?.key)
    {
      var updatedPage = {...selectedPage, data:{...selectedPage.data, 
        [val?.key]: val?.value
      }}
    }
    else
    {
      var updatedPage = {...selectedPage, data:{...selectedPage.data, 
                                                image_id: val?.image_id,
                                                image: val?.image
                                              }}
    }
    console.log('updatedPage',updatedPage);
    setSelectedPage(updatedPage);
  }

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div className={classes.InductionEditorContainer}>

          <div className={classes.inductionMenuWrapper}>  
            <h3>Induction</h3>
            
            <div className={classes.pageItemWrapper}>
                {induction?.data.pages?.map((item) => (
                <div>              
                  <div className={selectedPage.id == item.id ? classes.pageItemSelected : classes.pageItem} onClick={() => updateSelectedPage(item)}>
                    <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                    <img src={selectedPage.id == item.id ? pageIconSelected : pageIcon} />
                    <span className={classes.pageItemTitle}>{item.title}</span>
                  </div>

                  {(item.data?.rules && item.id == selectedPage.id && item.type=='rules') && (
                    <div className={classes.ruleItemWrapper}>                     
                      {item.data?.rules?.map((item) => (
                         <div>
                          <div onClick={() => updateSelectedRule(item)} className={classes.ruleItem} style={{background:item?.background, color: item?.textColor}}>
                            <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                            
                            <div className={classes.ruleIconWrapper}>
                              <div style={{backgroundColor: !isNil(item?.iconBackground) ? item?.iconBackground : item?.color}}>
                                {!isNil(item?.iconType) ? (
                                  <IconComponent
                                    icon={item?.iconType}
                                    color={item?.iconColor ? item?.iconColor : "white"}
                                    size={"80%"}
                                  />
                                ) : (
                                  <img src={item?.icon} />
                                )}
                              </div>
                            </div>
                            
                            {item.title}
                          </div>

                          {item.sections?.map((sectionItem) => (
                            <div onClick={() => updateSelectedSection(sectionItem)} className={classes.sectionItem} style={{background:item?.background, color: item?.textColor}}>
                               {sectionItem.title}
                            </div>
                          ))}
                          </div>
                      
                      ))}      
                    </div>
                  )}
                </div>
                ))}
            </div>

            
            
            {/*selectedRule.sections && (
              <div className={classes.sectionItemWrapper}>
                {selectedRule.sections?.map((item) => (
                
                    <div onClick={() => updateSelectedSection(item)} className={classes.sectionItem}>{item.title}</div>
                
                ))}      
              </div>
                )*/}
          </div>
            
          <div className={classes.pageEditorWrapper}>                 
            <h3>Editor</h3>
            <div className={classes.pageEditorContainer}>  

                  {languageOptions && (
                    <div className={classes.Container}>
                      <label className={classes.BlockLabel}>
                        Language
                      </label>
                      <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Select Language"}
                        onChange={(val) => {
                          //setSelectedOption(val.value);
                          
                          setSelectedLanguage({
                            code: val.value,
                            name: val.label,
                          });
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={languageOptions.filter(
                          (option) => option.value === selectedLanguage?.code
                        )}
                        options={languageOptions}
                        isSearchable={false}
                      />
                    </div>
                  )} 


                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.title_id ?  translations[selectedLanguage.code][selectedPage?.title_id] : ' ' }
                  name={"Heading Text"}
                  characterLimit={50}
                  onChange={(e) => {
                    var newPageUpdate = { ...selectedPage }
                    newPageUpdate.title = e.target.value;
                    setSelectedPage(newPageUpdate);
                  }}
                  label={"Heading Text"}
                  placeholder={"Heading Text"}
                  error={""}
                  tooltipText={
                    "Heading text, also known as the page name"
                  }
                />
                
                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.data?.subtitle_id ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] : ' '}
                  name={"Supporting Text"}
                  characterLimit={50}
                  onChange={(e) => {
                    updatePageData({key:'subtitle',value:e.target.value});
                  }}
                  label={"Supporting Text"}
                  placeholder={"Supporting Text"}
                  error={""}
                  tooltipText={
                    "Supporting text, appears underneath the heading"
                  }
                />



                {selectedRule.title && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedRule?.title_id ? translations[selectedLanguage.code][selectedRule?.title_id]: ' '}
                    name={"Chapter Name"}
                    characterLimit={50}
                    onChange={(e) => {
                      var newChapterUpdate = { ...selectedRule }
                      newChapterUpdate.title = e.target.value;
                      setSelectedRule(newChapterUpdate);
                    }}
                    label={"Chapter Name"}
                    placeholder={"Chapter Name"}
                    error={""}
                    tooltipText={
                      "Chapter Name"
                    }
                  />
                )}

                {selectedSection.title && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedSection?.title_id ? translations[selectedLanguage.code][selectedSection?.title_id] : ' '}
                    name={"Section Name"}
                    characterLimit={50}
                    onChange={(e) => {
                      var newSectionUpdate = { ...selectedSection }
                      newSectionUpdate.title = e.target.value;
                      //setSelectedPage(newPageUpdate);
                    }}
                    label={"Section Name"}
                    placeholder={"Section Name"}
                    error={""}
                    tooltipText={
                      "Section Name"
                    }
                  />
                )}
                
                {/* Here we bring the forms based on selected page type */} 

                {selectedPage.type == 'post_login' && (
                  <Prequalification page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'introduction' && (
                  <Introduction page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'welcome' && (
                  <Video page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'all-chapters-complete' && (
                  <AllChaptersCompleteForm page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'complete' && (
                  <InductionCompleteForm page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'site-rules' && (
                  <RulesForm page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'rules' && (
                  <ChaptersForm page={selectedPage} chapter={selectedRule} 
                                section={selectedSection} 
                                updatePageData={updatePageData} 
                                setSelectedSection={setSelectedSection} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                  />
                )}



                <button
                    id={"save"}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.darkButton}
                  >
                    Save
                </button>






            </div>
          </div>

          <div className={classes.pagePreviewWrapper}>
            <h3>Preview</h3>
            <div className={classes.pagePreviewContainer}>
              <div className={classes.pagePreviewContent}>
                  <div className={classes.pagePreviewHeaderWrapper}>
                    <Header
                    menu={false}
                    open={false}
                    setOpen={function(){}}
                  />
                  </div>

                  {/* These are different page components for preview - opened in preview mode */}

                  {selectedPage?.type == "introduction" && (
                    <div>
                        <Introductory selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}

                  {selectedPage?.type == "welcome" && (
                    <div>
                        <Welcome selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}

                  {selectedPage?.type == "take-a-look" && (
                    <div>
                        <LookAround selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}

                  {selectedPage?.type == "all-chapters-complete" && (
                    <div>
                        <AllChaptersComplete selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}

                  {selectedPage?.type == "site-rules" && (
                    <div>
                        <SiteRules selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}

                  {selectedPage?.type == "complete" && (
                    <div>
                        <Complete selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}


                  {selectedPage?.type == "assessment" && (
                    <div>
                        <AssesmentPage selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}


                  {selectedPage?.type == "post_login" && (
                    <div>
                        <PostLogin selectedInduction={induction} isPreview={true} pageData={selectedPage} />
                    </div>
                  )}




                  {selectedPage?.type == "rules" && selectedSection?.title == '' && (
                      
                    <div>
                        <Induction selectedInduction={induction} induction={induction} isPreview={true} pageData={selectedPage} history={history} />
                    </div>
                      
                  )}

                  {selectedSection && (
                    <div>
                        {selectedSection.data?.components?.map((item) =>
                          getComponent(item.type, item, () => {})
                        )}
                    </div>
                  )}

              </div>
            </div>
          </div>


            
        </div>
        

      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  induction: makeSelectInductionDetail(),
  languages: makeSelectLanguages(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInductions: (val) => dispatch(getInductionDetailReq(val)),
    updateInduction: (val) => dispatch(updateInductionReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionEditor);
