import React, { useState, useEffect, useRef } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import cn from "classnames";

import classes from "./cardSort.module.scss";
import TickIcon from "./tick.svg";

import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "../FormattedHTMLMessage";
import { delay } from "utils/helper";

const { useDrop, useDrag } = require("react-dnd");

export const MovableItem = ({
  name,
  index,
  currentColumnName,
  moveCardHandler,
  setItems,
  description,
  description_id,
  title,
  title_id,
  icon,
  feedback,
  onDrag,
  items,
  categoryIndex,
  isCategory,
  cIndex,
  setAnimatedColumn,
  wrongCount,
  setWrongCount,
  isDrop,
}) => {
  const changeItemColumn = async (
    currentItem,
    columnName,
    id,
    answer,
    catIndex
  ) => {
    const selectedItem = items.find((item) => item.name === currentItem.name);

    const animatedItem = {
      ...selectedItem,
      id: answer,
      column: "ANIMATE",
      selectedAnswer: id,
      catIndex: catIndex,
    };
    const otherItems = items.filter((item) => currentItem.name !== item.name);
    setItems([...otherItems, animatedItem]);
    if (answer !== id) {
      setWrongCount(1);
    }
    setAnimatedColumn(catIndex);

    await delay(700);
    setAnimatedColumn(false);
    if (answer !== id) {
      setItems(items);
      setWrongCount(0);
    } else {
      const newItem = {
        ...selectedItem,
        id: answer,
        column: columnName,
        selectedAnswer: id,
      };
      setAnimatedColumn(false);
      setItems([...otherItems, newItem]);
      setWrongCount(0);
    }
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "Our first type",

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Time to actually perform the action
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult?.id !== undefined) {
        const dragIndex = item.index;
        const hoverIndex = index;

        moveCardHandler(dragIndex, hoverIndex);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      index,
      name,
      currentColumnName,
      description,
      description_id,
      title,
      title_id,
      icon,
      feedback,
    },
    type: "Our first type",
    canDrag: (item) => {
      console.log("drag", item);
      return true;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult?.id !== undefined) {
        const { name, id, answer, ccIndex } = dropResult;
        changeItemColumn(item, name, id, answer, ccIndex);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={cn(
        `${classes.Question}`,
        (currentColumnName === "START" || currentColumnName === "ANIMATE") &&
          "movable-item",
        onDrag && classes.hidden,
        currentColumnName === "ANIMATE" &&
          isCategory &&
          cIndex === categoryIndex &&
          !wrongCount &&
          classes.fadeOut,
        currentColumnName === "ANIMATE" &&
          isCategory &&
          cIndex === categoryIndex &&
          wrongCount &&
          categoryIndex === 0 &&
          classes.moveBackLeft,
        currentColumnName === "ANIMATE" &&
          isCategory &&
          cIndex === categoryIndex &&
          wrongCount &&
          categoryIndex === 1 &&
          classes.moveBackRight
      )}
      style={{
        zIndex: index,
        position: "absolute",
        top: "6px",
        left: "6px",
        height: currentColumnName === "START" && "132px",
        opacity: currentColumnName !== "START" && 0,
      }}
    >
      <div className={classes.descriptionWrapper}>
        <span className={classes.counter}>
          {currentColumnName === "START"
            ? items.length -
              items.filter((item) => item.column === "START").length +
              1
            : items.length -
              items.filter((item) => item.column === "START").length}
          /{items.length}
        </span>
        <img
          className={cn(
            currentColumnName !== "START"
              ? classes.successIcon
              : classes.hidden,
            classes.successIcon
          )}
          src={TickIcon}
          alt={"Tick Icon"}
        />
        {icon && <img className={classes.icon} src={icon} alt={description} />}
        {title && (
          <div className={classes.TitleDescription}>
            <b>
              <FormattedHTMLMessage value={title} id={title_id} />
            </b>
          </div>
        )}
        <div className={classes.QuestionDescription}>
          <FormattedHTMLMessage value={description} id={description_id} />
        </div>
        <div className={classes.DotsContainer}>
          <span className={classes.dots}>...</span>
          <span className={classes.dots}>...</span>
        </div>
      </div>
    </div>
  );
};

export const Column = ({
  children,
  className,
  title,
  titleId,
  dataId,
  id,
  answer,
  index,
  animatedColumn,
  isDrop,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "Our first type",
    drop: () => ({ name: id, id: id, answer: answer, ccIndex: index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    // Override monitor.canDrop() function

    canDrop: (item) => {
      return true;
    },
  });

  return (
    <div
      className={cn(
        title !== "START" ? classes.cardWrapper : classes.mainCardWrapper,
        title === "START" && isDrop && classes.eventsNone
      )}
      style={{ border: isOver && "4px solid transparent !important" }}
    >
      <div
        ref={drop}
        className={cn(`${classes.Card} ${className}`)}
        style={{
          background:
            title !== "START" && isOver
              ? "#000000"
              : title !== "START" && animatedColumn === index && answer === id
              ? "#6FCF97"
              : title !== "START" &&
                animatedColumn === index &&
                answer !== id &&
                "#E26C82",
          border: title !== "START" && isOver && "4px solid #DDDDDD",
        }}
        contentEditable={false}
        data-text={id}
        data-id={dataId}
      >
        {title !== "START" && (
          <span
            style={{
              color: title !== "START" && isOver && "#ffffff",
            }}
            className={cn(
              classes.cardCategoryText,
              title && title.length > 160 && classes.fontSmall
            )}
          >
            <FormattedHTMLMessage
              value={title}
              id={titleId ? titleId : "test"}
            />
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

export const calcResults = (items) => {
  return items.reduce(
    (acc, item) => (item.answer === item.selectedAnswer ? (acc += 1) : acc),
    0
  );
};
