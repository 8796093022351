import classes from "./welcome.module.scss";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { makeSelectSelectedInduction } from "store/induction/selector";
import { setCurrentSection, getSectionTime } from "store/time/actions";
import { makeSelectProgress } from "store/progress/selector";

import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Heading from "components/UI/heading";
import VideoPlayer from "components/UI/videoPlayer";

import { Link } from "react-router-dom";
import { useLocate, saveProgress } from "utils/hooks";
import Select from "react-select";
import { isEmpty, isNil } from "lodash";
// import { useScreenOrientation } from "utils/hooks";
import { defaultLanguages } from "utils/languages";
import ArrowIcon from "assets/icons/arrow-gray.svg";
import SubtitleIcon from "assets/icons/subtitle1.svg";
import cn from "classnames";
import { FormattedMessage } from "react-intl";

const Welcome = ({ history, selectedInduction, isPreview = false, pageData }) => {
  var { previous, current, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

    //if we are on preview mode, take the data from the props
    if(isPreview)
    {
      current = pageData;
    }

  useEffect(() => {
    if(!isPreview)
       saveProgress(selectedInduction, history.location.pathname);


    //load Vimeo player javascript
    const externalScript = document.createElement("script");
    externalScript.onerror = function() { setVideoFinished(true) }; //fallback
    externalScript.id = "external";
    externalScript.async = false;
    externalScript.type = "text/javascript";
    externalScript.setAttribute("crossorigin", "anonymous");
    document.body.appendChild(externalScript);
    externalScript.src = 'https://player.vimeo.com/api/player.js';
    
    //wait for a while and
    //hook the events
    setTimeout(function()
    {
      if(typeof(window.Vimeo) != undefined)
        {
          var vm = new window.Vimeo.Player(document.querySelector('iframe'));

          if(vm)
          {
            vm.ready().catch(function(error) {
              setVideoFinished(true);
            });
  
            vm.on('ended', function() {
                setVideoFinished(true);
            }); 
          }
        }
        else
           setVideoFinished(true); //fallback
    },500);
    


  }, []);
  const [isTranscriptOpen, setTranscriptOpen] = useState(true);
  const [videoLanguage, setSelectedVideoLanguage] = useState("");
  const [selectedSubtitle, setSelectedSubtitle] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);
  const [subtitleOptions, setSubtitleOptions] = useState([]);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isVideoFinished, setVideoFinished] = useState(true); //DISABLED FOR NOW! ENABLE WHEN CONFIRMED

  // const orientation = useScreenOrientation();

  useEffect(() => {
    if (!isNil(current)) {
      const langOpts = current?.data?.video?.map((item) => {
        return {
          value: item.url,
          label: defaultLanguages.find((lang) => lang.code === item.language)
            ?.name,
          transcript: item.transcript,
        };
      });
      setLanguageOptions(langOpts);
      if (!isEmpty(langOpts) && !isNil(langOpts)) {
        setSelectedVideoLanguage(langOpts[0]);
      }
    }

    const subOpts = current?.data?.video_subtitles?.map((item) => {
      return {
        value: defaultLanguages?.find((lang) => lang.code === item)?.isocode,
        label: defaultLanguages?.find((lang) => lang.code === item)?.name,
      };
    });
    setSubtitleOptions(subOpts);
  }, [current]);

  return (
    <>
      <div className={classes.Container}>
        <Heading 
          title={current?.title} 
          titleId={isPreview ? current?.title : current?.title_id} 
          description={current?.data?.subtitle} 
          descriptionId={isPreview ? current?.data?.subtitle : current?.data?.subtitle_id} 
        />{" "}
        
        <div style={{ width: "100%" }}>
          <VideoPlayer
            // orientation={orientation}
            subtitle={selectedSubtitle}
            videoUrl={videoLanguage?.value}
          />
        </div>
        {/* VIDEO SETTINGS  */}
        <div className={classes.videoSettingsContainer}>
          <div className={classes.videoHeader}>
            <span>
              <FormattedMessage
                id={"welcome.video.settings.title"}
                defaultMessage={"Video Settings"}
              />
            </span>
            <img
              onClick={() => setSettingsOpen(!isSettingsOpen)}
              className={cn(
                classes.collapseIcon,
                !isSettingsOpen && classes.turn
              )}
              src={ArrowIcon}
              alt={"Arrow Icon"}
            />
          </div>
          {isSettingsOpen && (
            <>
              <div className={classes.videoCol}>
                <span>
                  <FormattedMessage
                    id={"welcome.video.audio"}
                    defaultMessage={"Audio"}
                  />
                </span>
                <div className={classes.selectCol}>
                  <Select
                    styles={{
                      control: () => ({
                        width: 100,
                        height: 20,
                        display: "flex",
                        background: "#FFFFFF",
                        color: "#40507E",
                        border: `none`,
                        boxSizing: "border-box",
                      }),
                      container: () => ({
                        width: 100,
                        height: 20,
                        position: "relative",
                        boxSizing: "border-box",
                      }),
                      placeholder: () => ({
                        color: "#40507E",
                        fontFamily: "Open Sans",
                        marginLeft: 5,
                        fontSize: 14,
                      }),
                      valueContainer: () => ({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                      }),
                      singleValue: () => ({
                        color: "#40507E !important",
                        // marginTop: "10px",
                        width: "60px",
                      }),
                      indicatorSeparator: () => ({
                        display: "none",
                      }),
                    }}
                    value={videoLanguage}
                    options={languageOptions}
                    tabSelectsValue={videoLanguage}
                    onChange={(val) => {
                      setSelectedVideoLanguage(null);
                      setSelectedVideoLanguage(val);
                    }}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className={classes.videoCol}>
                <div className={classes.subtitleCol}>
                  <span>
                    {" "}
                    <FormattedMessage
                      id={"welcome.video.subtitles"}
                      defaultMessage={"Subtitles"}
                    />
                  </span>
                  <img src={SubtitleIcon} alt={"SubtitleIcon"} />
                </div>
                <div className={classes.selectCol}>
                  <Select
                    styles={{
                      control: () => ({
                        width: 100,
                        height: 20,
                        display: "flex",
                        background: "#FFFFFF",
                        color: "#40507E",
                        border: `none`,
                        boxSizing: "border-box",
                      }),
                      container: () => ({
                        width: 100,
                        height: 20,
                        position: "relative",
                        boxSizing: "border-box",
                      }),
                      placeholder: () => ({
                        color: "#40507E",
                        fontFamily: "Open Sans",
                        fontSize: 14,
                      }),
                      valueContainer: () => ({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                      }),
                      singleValue: () => ({
                        color: "#40507E !important",
                        width: "60px",
                      }),
                      indicatorSeparator: () => ({
                        display: "none",
                      }),
                    }}
                    options={subtitleOptions}
                    tabSelectsValue={selectedSubtitle}
                    onChange={(val) => setSelectedSubtitle(val)}
                    isSearchable={false}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/* VIDEO TRANSCRIPT */}
        {isTranscriptOpen && (
          <div className={classes.transcriptContainer}>
            <h3>
              <FormattedMessage
                id={"welcome.videoTranscript.title"}
                defaultMessage={"Video Transcript"}
              />
            </h3>
            <p>
              <FormattedMessage
                id={videoLanguage?.transcript_id ? videoLanguage?.transcript_id : '_'}
                defaultMessage={videoLanguage?.transcript}
              />
            </p>

            <button
              onClick={() => {
                setTranscriptOpen(false);
              }}
            >
              <FormattedMessage
                id={"welcome.videoTranscript.button"}
                defaultMessage={"OK"}
              />
            </button>
          </div>
        )}
      </div>
      {!isPreview && isVideoFinished && (
        <Footer>
          <Link className={classes.Link} to={next?.url}>
            <Button
              subText={next?.show_in_menu ? next?.menu_name : next?.title}
              type={"submit"}
              id="apply-button"
              text="Continue"
              theme="blue"
              formatID="continue.button"
            />
          </Link>
        </Footer>
      )}

    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
