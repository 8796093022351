import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

//action start
import {
  postLoginRequest,
  checkInRequest,
  prequalRequest,
} from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReason,
  makeSelectPrequal,
} from "store/auth/selector";
import { makeSelectSelectedInduction } from "store/induction/selector";
//selectors end

//components start
import Heading from "components/UI/heading";
import Footer from "components/UI/footer";
import Button from "components/UI/button";
import ItemCheckbox from "components/UI/itemCheckbox";
import { Formik, Form, Field } from "formik";
//component end

import classes from "./postLogin.module.scss";
import queryString from "query-string";
import { useLocate } from "utils/hooks";
import { isEmpty } from "lodash";

import Lottie from "react-lottie";
import Loading from "components/UI/loading";

//animations
import CheckAnimation from "assets/animations/Check-small-v3.json";

const PostLogin = (props) => {
  var { current } = useLocate(props.selectedInduction, "/post-login");
  const { next } = useLocate(props.selectedInduction, "/post-login");

  const [cards, setCards] = useState([]);
  const [site, setSite] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [pageTitle, setPageTitle] = useState(current?.title);
  const [pageSubTitle, setPageSubTitle] = useState(current?.data?.subtitle);
  const [redirectRoute, setRedirectRoute] = useState();

  if (props?.isPreview) current = props?.pageData;

  useEffect(() => {
    if (current && isEmpty(cards)) {
      //console.log('entered here',props.history);

      const opt = current?.data?.questions?.map((item, index) => {
        return {
          id: index + 1,
          title: item.name,
          checked: props.history?.location?.completed?.includes(index + 1)
            ? true
            : false,
          formatID: props?.isPreview ? item.name : item.id,
          type: item?.type ? item?.type : "acknowledgement",
          key: item?.key,
          captureTitle: item?.capture_title,
          captureTitleId: item?.capture_title_id,
          captureDescription: item?.capture_description,
          captureDescriptionId: item?.capture_description_id,
          captureSubtitle: item?.capture_subtitle,
          captureSubtitleId: item?.capture_subtitle_id,
          capturePunchline: item?.capture_punchline,
          capturePunchlineId: item?.capture_punchline_id,
        };
      });

      setCards(opt);
    }
  }, [current]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSite(parsed.site);
    setRedirectRoute(parsed.redirect);
    props.getPrequal();
  }, []);

  useEffect(() => {
    //console.log('prequal',props.prequal);

    if (props?.prequal && !isEmpty(cards)) {
      const newOptions = cards.map((item) => {
        if (item.type === "custom") {
          //halilk: if the api says it completed, show a check icon
          var checked = false;
          if (props.prequal[item?.key][0]?.success == 1) checked = true;

          return {
            ...item,
            checked: checked,
          };
        } else {
          return item;
        }
      });
      setCards(newOptions);
    }
  }, [props.prequal]);

  const setCheckBoxHandler = (val) => {
    const newOptions = cards.map((item) => {
      if (item.id === val) {
        //halilk: if the type is document, then move to camera
        if (item?.type == "document") {
          //halilk: send the already completed items so you can put back when we are back
          var completed = [];
          for (var i = 0; i < cards.length; i++) {
            if (cards[i]?.checked == true) completed.push(cards[i].id);
          }

          props.history.push({
            pathname: "/camera",
            data: {
              item_id: item.id,
              item_name: item.title,
              completed: completed,
              capture_title: item?.captureTitle,
              capture_title_id: item?.captureTitleId,
              capture_description: item?.captureDescription,
              captureDescription_id: item?.captureDescriptionId,
              capture_subtitle: item?.captureSubtitle,
              capture_subtitle_id: item?.captureSubtitleId,
              capture_punchline: item?.capturePunchline,
              capture_punchline_id: item?.capturePunchlineId,
            },
          });
          return {
            id: item.id,
            title: item.title,
            checked: !item.checked,
            formatID: props?.isPreview ? item.name : item.formatID,
          };
        } else if (item?.type == "custom") {
          return item;
        } else {
          return {
            id: item.id,
            title: item.title,
            checked: !item.checked,
            formatID: props?.isPreview ? item.name : item?.formatID,
          };
        }
      } else {
        return item;
      }
    });

    setCards(newOptions);
  };

  useEffect(() => {
    if (
      cards.filter((item) => item?.checked === true).length ===
        current?.data?.questions?.length &&
      !success
    ) {
      setTimeout(() => {
        onSubmit();
      }, 500);
    }
  }, [cards]);

  const onSubmit = () => {
    const validator = cards.filter((item) => {
      if (item.checked === true) {
        return null;
      } else {
        return item.id;
      }
    });
    return props.onSubmitForm({
      items: validator,
      site: site,
      redirect: redirectRoute,
      callBack: () => {
        setSuccess(true);
        setSubmitted(true);
        setPageTitle(`Thanks ${props.user && props.user.first_name}!`);
        setPageSubTitle(`Tap the continue button below to proceed.`);
      },
    });
  };

  if (props.loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Formik enableReinitialize initialValues={cards} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Form className={classes.Form}>
              <Heading
                title={success ? "Thanks {name}!" : pageTitle}
                titleId={
                  props?.isPreview
                    ? current?.title
                    : success
                    ? "postlogin.success.title"
                    : current?.title_id
                }
                titleValues={{
                  name: props.user && props.user.first_name,
                }}
                description={
                  success
                    ? `Tap the continue button below to proceed.`
                    : pageSubTitle
                }
                descriptionId={
                  props?.isPreview
                    ? current?.data?.subtitle
                    : success
                    ? "postlogin.success.description"
                    : current?.data?.subtitle_id
                }
              />

              <div className={classes.successWrapper}>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: CheckAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                      progressiveLoad: true,
                    },
                  }}
                  width={"60%"}
                  isClickToPauseDisabled={true}
                  isPaused={!success}
                  title={"animation"}
                  ariaLabel={"animation"}
                />
                {/* <img alt={"Tick"} src={Tick} className={classes.icon} /> */}
              </div>
              <div
                className={
                  submitted
                    ? classes.ItemContainerSubmitted
                    : classes.ItemContainer
                }
              >
                {values.map((item, index) => (
                  <Field
                    value={item?.checked}
                    key={item?.id}
                    onPress={() => setCheckBoxHandler(item.id)}
                    onClick={() => setCheckBoxHandler(item.id)}
                    title={
                      props?.isPreview
                        ? current?.data?.questions[index]?.name
                        : item?.title
                    }
                    checked={item?.checked}
                    formatID={props?.isPreview ? item?.title : item?.formatID}
                    type={item?.type}
                    component={ItemCheckbox}
                    textItems={{name: props.user.first_name + ' ' + props.user.last_name}}
                  />
                ))}
              </div>
            </Form>
          );
        }}
      </Formik>

      {!props?.isPreview && (
        <Footer isHidden={!submitted}>
          <Button
            formatID={"postlogin.success.button"}
            text={""}
            theme="blue"
            onClick={() => props.history.push(next?.url)}
          />
        </Footer>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  reason: makeSelectReason(),
  selectedInduction: makeSelectSelectedInduction(),
  prequal: makeSelectPrequal(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(postLoginRequest(val)),
    checkIn: (val) => dispatch(checkInRequest(val)),
    getPrequal: (val) => dispatch(prequalRequest(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostLogin));
