// @flow
import { all, fork } from "redux-saga/effects";
import loginRequestSaga from "./sagas/loginRequest";
import postLoginRequestSaga from "./sagas/postLoginRequest";
import supervisorLoginRequestSaga from "./sagas/supervisorLoginRequest";
import supervisorEmailPasswordRequestSaga from "./sagas/supervisorNormalLoginRequest";
import setMeRequestSaga from "./sagas/setMeRequest";
import getMeRequestSaga from "./sagas/getMeRequest";
import staffVerifySaga from "./sagas/staffVerify";
import checkInSaga from "./sagas/checkIn";
import checkOutSaga from "./sagas/checkOut";
import siteInductionSaga from "./sagas/siteInduction";
import getCovidQuestionSaga from "./sagas/getCovidQuestions";
import setCovidQuestionSaga from "./sagas/setCovidQuestions";
import getSiteHazardsSaga from "./sagas/getSiteHazards";
import tincanLoginRequestSaga from "./sagas/tincanLogin";
import prequalRequestSaga from "./sagas/prequalRequest";


function* authSaga() {
  yield all([
    fork(loginRequestSaga),
    fork(postLoginRequestSaga),
    fork(supervisorLoginRequestSaga),
    fork(supervisorEmailPasswordRequestSaga),
    fork(setMeRequestSaga),
    fork(getMeRequestSaga),
    fork(staffVerifySaga),
    fork(checkInSaga),
    fork(checkOutSaga),
    fork(siteInductionSaga),
    fork(getCovidQuestionSaga),
    fork(setCovidQuestionSaga),
    fork(getSiteHazardsSaga),
    fork(tincanLoginRequestSaga),
    fork(prequalRequestSaga),
  ]);
}

export default authSaga;
