import React, { useState, useEffect } from "react";
import classes from "./tincan.module.scss";
import QRCode from "react-qr-code";
import queryString from "query-string";
import DropImage from "assets/tincan/wrap.svg";
import KeepSafeLogo from "assets/logo/logo.png";
import { FormattedMessage } from "react-intl";

import { dynamicSiteSelectorVariables } from "services/config";

const TinCan = () => {
  const [qrData, setQrData] = useState(`${window.location.origin}/tincan`);

  const siteDomain = dynamicSiteSelectorVariables.find(
    (item) => item.domain === window?.location?.hostname
  );
  const siteName = siteDomain?.induction_name;
  const parsed = queryString.parse(window.location.search);

  const actor = JSON.parse(parsed?.actor);

  useEffect(() => {
    if (actor)
      setQrData(
        `${window.location.origin}/tincan-login?auth=` +
          encodeURI(parsed?.auth) +
          "&activity_id=" +
          encodeURI(parsed?.activity_id) +
          "&actor=" +
          encodeURI(parsed?.actor)
      );
  }, []);

  console.log("parsed", actor);

  return (
    <div className={classes.Container}>
      <div className={classes.siteSpecificInductionWrapper}>
        <div className={classes.startSectionWrapper}>
          <img src={DropImage} />
        </div>
        <h2>{siteName}</h2>
        <h3>
          <FormattedMessage
            defaultMessage={"Start Site Specific Induction"}
            id={"qr.page.title"}
          />
        </h3>

        <div className={classes.middleSectionWrapper}>
          <div className={classes.qrWrapper}>
            <QRCode id="QRCode" value={qrData} size={"80%"} />
          </div>
        </div>

        <span className={classes.textBold}>
          <FormattedMessage
            defaultMessage={"Use your mobile to scan the QR code above."}
            id={"qr.page.text1"}
          />
        </span>

        <span className={classes.text}>
          <FormattedMessage
            defaultMessage={
              "You'll be prompted to open the QR code in your default browser."
            }
            id={"qr.page.text2"}
          />
        </span>

        <span className={classes.textBold}>
          <FormattedMessage
            defaultMessage={"Enjoy the experience!"}
            id={"qr.page.text3"}
          />
        </span>
      </div>
      <div className={classes.footer}>
        <span>Powered By</span> <img src={KeepSafeLogo} />
      </div>
    </div>
  );
};

export default TinCan;
