import React from "react";
import Header from "components/UI/header";
import Heading from "components/UI/heading";
import { FormattedMessage } from "react-intl";
import classes from "./auth.module.scss";

const ErrorScreen = ({ setError }) => {
  return (
    <div className={classes.errorScreen}>
      <Header transparent />
      <Heading
        title={"Oops! Looks like there's a registration error"}
        titleId={"auth.heading.error.title"}
      />
      <div className={classes.errorTextWrapper}>
        <span className={classes.errorText}>
          <b>
            <FormattedMessage
              defaultMessage={"We couldn't find a match for you in ChargeUP."}
              id={"auth.error.description1"}
            />
          </b>
        </span>
        <span className={classes.errorText}>
          <FormattedMessage
            defaultMessage={
              "Make sure you are using the same email you used for ChargeUP. Contact your coordinator for assistance."
            }
            id={"auth.error.description2"}
          />
        </span>
        <span className={classes.errorText}>
          <FormattedMessage
            defaultMessage={"Please make other arrangements with your host."}
            id={"auth.error.description3"}
          />
        </span>
      </div>
      <div className={classes.arrowWrapper} onClick={() => setError(false)}>
        <img alt={"back arrow"} src={"/arrow.png"} />
      </div>
    </div>
  );
};

export default ErrorScreen;
