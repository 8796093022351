import React, { useEffect } from "react";
import classes from "./intro.module.scss";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "../FormattedHTMLMessage";

const Intro = ({ image, setFooterVisibilityReq }) => {
  useEffect(() => {
    setFooterVisibilityReq({ isFooterVisible: true });
  }, []);

  return (
    <div className={classes.Container}>
      {image && (
        <>
          <img
            src={image}
            className={classes.Image}
            alt={"introduction image"}
          />
        </>
      )}
    </div>
  );
};

export default Intro;
