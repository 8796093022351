import React, { useEffect, useState } from "react";
import { ReactComponent as RightIcon } from "./turn.svg";
import { ReactComponent as CheckIcon } from "./check.svg";

import classes from "./flipCard.module.scss";
import { isNil, isEmpty, some } from "lodash";
import cn from "classnames";
import { FormattedMessage } from "react-intl";

const FlipCard = (props) => {
  const {
    title,
    title_id,
    content,
    content_id,
    items_per_row,
    cards,
    setFooterVisibilityReq,
    interactive,
  } = props;

  const [currentFlipItems, setCurrentFlipItems] = useState([]);

  const [isCompleteArray, setCompleteArray] = useState([]);

  useEffect(() => {
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  useEffect(() => {
    if (
      !isEmpty(isCompleteArray) &&
      isCompleteArray?.filter((item) => item.total >= 2).length === cards.length
    ) {
      setFooterVisibilityReq({ isFooterVisible: true });
    }
  }, [isCompleteArray]);

  useEffect(() => {
    if (!isEmpty(cards)) {
      const compArray = cards.map((item, index) => {
        return {
          id: index,
          total: 0,
        };
      });
      setCompleteArray(compArray);
    }
  }, [cards]);

  // Callbacks
  const handleFlip = (id) => {
    const newIsCompleteArray = isCompleteArray?.map((item) => {
      return {
        ...item,
        total: item.id === id ? item.total + 1 : item.total,
      };
    });
    setCompleteArray(newIsCompleteArray);

    if (currentFlipItems.includes(id)) {
      setCurrentFlipItems([...currentFlipItems.filter((item) => item !== id)]);
    } else {
      setCurrentFlipItems([id, ...currentFlipItems]);
    }
  };

  // Style Mapper
  const getStyle = () => {
    const maxItemWidth = items_per_row === 3 ? "32%" : null;
    const style = {};
    style.position = "relative";
    if (maxItemWidth) {
      style.maxWidth = maxItemWidth;
      style.height = "108px";
    } else if (items_per_row === 2) {
      style.minWidth = "48%";
      style.height = "168px";
      style.marginRight = "1%";
    } else {
      style.minWidth = "100%";
      style.minHeight = "200px";
    }

    return style;
  };

  const isCurrent = (key) => {
    return currentFlipItems.includes(key);
  };

  return (
    <div className={classes.Container}>
      {!isNil(title) && title.trim() != '' && (
        <div className={classes.Header}>
          <FormattedMessage
            id={title_id ? title_id : title}
            defaultMessage={title}
          />
        </div>
      )}
      {!isNil(content) && content.trim() != '' && (
        <div className={classes.Content}>
          <FormattedMessage
            id={content_id ? content_id : content}
            defaultMessage={content}
          />
        </div>
      )}
      <div className={classes.ImageContainer}>
        {cards?.map((card, key) => {
          return (
            <div
              style={{
                ...getStyle(),
              }}
            >
              <div
                className={classes.Wrapper}
                style={{
                  height: getStyle()?.height,
                }}
              >
                <input
                  className={classes.Input}
                  type="checkbox"
                  id="pricing"
                  name="pricing"
                  checked={isCurrent(key)}
                />

                <div className={classes.Wrap3D}>
                  <div className={classes.Card3DWrap}>
                    <div
                      className={`${classes.FlipCard} ${classes.Front}`}
                      onClick={() => handleFlip(key)}
                    >
                      {!isEmpty(isCompleteArray) &&
                      isCompleteArray[key].total >= 2 ? (
                        <div
                          className={cn(
                            classes.isCompletedCheckWrapper,
                            isCurrent(key) && classes.hidden
                          )}
                        >
                          <CheckIcon />
                        </div>
                      ) : (
                        <div
                          className={
                            classes.isCompletedCheckWrapper +
                            " " +
                            classes.hidden
                          }
                        >
                          <CheckIcon />
                        </div>
                      )}
                      <div className={classes.ContentWrapper}>
                        <div className={classes.CardContent}>
                          <span className={classes.cardTitle}>
                            {!isNil(
                                card.front_title
                              ) ? (
                              <FormattedMessage
                                defaultMessage={card.front_title}
                                id={card.front_title_id}
                              />
                            ) : null}
                          </span>
                          <span className={classes.cardDescription}>
                            {!isNil(
                                card.front
                              ) ? (
                              <FormattedMessage
                                defaultMessage={card.front}
                                id={card.front_id}
                              />
                            ) : null}
                          </span>
                        </div>

                        <div className={classes.FlipIconWrapper}>
                          <div className={classes.IconBackground}>
                            <RightIcon />
                          </div>
                        </div>
                        {!isEmpty(card.front_image) && (
                          <div className={classes.Image} style={{ backgroundImage: "url(" + card.front_image + ")" }}>
                            &nbsp;
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${classes.FlipCard} ${classes.Back}`}
                      onClick={() => handleFlip(key)}
                    >
                      {!isEmpty(isCompleteArray) &&
                        isCompleteArray[key].total >= 2 && (
                          <div
                            className={cn(
                              classes.isCompletedCheckWrapper,
                              !isCurrent(key) && classes.hidden
                            )}
                          >
                            <CheckIcon />
                          </div>
                        )}
                      <div className={classes.ContentWrapper}>
                        <div className={classes.CardContent}>
                          <span className={classes.cardTitleBack}>
                            {!isNil(
                                card.back_title
                              ) ? (
                              <FormattedMessage
                                defaultMessage={card.back_title}
                                id={card.back_title_id}
                              />
                            ) : null}
                          </span>
                          <span className={classes.cardDescription}>
                            {!isNil(
                                card.back
                              ) ? (
                              <FormattedMessage
                                defaultMessage={card.back}
                                id={card.back_id}
                              />
                            ) : null}
                          </span>
                        </div>

                        <div className={classes.FlipIconWrapper}>
                          <div className={classes.IconBackground}>
                            <RightIcon />
                          </div>
                        </div>
                        {!isEmpty(card.back_image) && (
                          <div className={classes.Image}>
                            <img src={card.back_image} alt="card back" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlipCard;
