import React,{useState,useEffect} from "react";
import Input from "../../../../../components/Supervisor/input";
import ImageUpload from "../../../Hazards/components/imageUpload";
import classes from "../editor.module.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";


const ChaptersForm = ({
page,
updatePageData, 
chapter, 
section,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
setSelectedSection,
translations,
selectedLanguage,
updateTranslation

}) => {


const [rules, setRules] = useState(page?.data?.rules);
const [initialState, setInitialState] = useState(true);
const [selectedComponent, setSelectedComponent] = useState(0);

const updateRule = (item, value) => {
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules});
}

useEffect(() => 
{
    if(initialState)
       return;

    if (inductionImage?.url) 
    {      
       var newComponentUpdate = [ ...section.data.components ]
                            
       for(var j = 0; j < newComponentUpdate.length; j++)
       {
           if(j == selectedComponent)
              newComponentUpdate[j] = {...newComponentUpdate[j],image_id: inductionImage?.id, image: inductionImage?.url};
       }
       
       var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
       setSelectedSection(updatedData);

    }
  }, [inductionImage]);

  //console.log('components',section?.data?.components);

  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Blocks List</h4>
        
        {section?.data?.components.map((item,index) =>
                       
            <>           
            <Input
                hasTooltip={true}
                order="column"
                value={item?.title_id ? translations[selectedLanguage.code][item?.title_id] : ' '}
                name={"component_"+index}
                characterLimit={120}
                onChange={(e) => {
                    //updateRule(index,e.target.value);
                }}
                label={"Title"}
                placeholder={"Title here"}
                error={""}
                tooltipText={
                "Title text"
                }
            />
            <br />
            
            {item?.type == 'image_text_full' && (
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />
            )}

            {item?.type == 'image_text_regular' && (
                <div>
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {

                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                        }
                        }}
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.header_id ? translations[selectedLanguage.code][item?.header_id] : ' '}
                        name={item?.header_id ? item?.header_id : "component_header_"+index}
                        characterLimit={120}
                        onChange={(e) => {
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],header:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.header_id,e.target.value);
                        }}
                        label={"Header"}
                        placeholder={"header here"}
                        error={""}
                        tooltipText={
                        "Header text"
                        }
                    />


                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.content_id ? translations[selectedLanguage.code][item?.content_id]  : ' '}
                        name={item?.content_id ? item?.content_id : "component_content_"+index}
                        characterLimit={250}
                        onChange={(e) => {
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);

                           //update translation
                           updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                        }}
                        label={"Content"}
                        placeholder={"Content here"}
                        error={""}
                        tooltipText={
                        "Content text"
                        }
                    />

                </div>
            )}

            {item?.type == 'hotspots' && (
              <div>  
               <br />
               <h4 className={classes.formHeading}>Modals</h4>

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] : ' '}
                    name={item?.content_id ? item?.content_id : "component_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Intro Modal Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content text for the hotspot intro modal. Supports HTML tags"
                    }
               />

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.button_label_id ? translations[selectedLanguage.code][item?.button_label_id] : ' '}
                    name={item?.button_label_id ? item?.button_label_id : "component_button_label_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],button_label:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.button_label_id, e.target.value);
                    }}
                    label={"Intro Modal Button Label"}
                    placeholder={"Button Label"}
                    error={""}
                    tooltipText={
                    "This is the button label for the hotspot intro modal"
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_title_id ? translations[selectedLanguage.code][item?.conclusion_title_id] : ' '}
                    name={item?.conclusion_title_id ? item?.conclusion_title_id : "component_conclusion_title_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_title:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_title_id, e.target.value);
                    }}
                    label={"Conclusion Title"}
                    placeholder={"Conclusion Title"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal title. Appears when all the hotspots are clicked."
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_content_id ? translations[selectedLanguage.code][item?.conclusion_content_id] : ' '}
                    name={item?.conclusion_content_id ? item?.conclusion_content_id : "component_conclusion_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Conclusion Content"}
                    placeholder={"Conclusion Content"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal content. Appears when all the hotspots are clicked. Supports HTML."
                    }
               />
               
               <br />
               <h4 className={classes.formHeading}>Hotspots</h4>

               {/* Hotspots */}
               {item?.hotspots.map((hotspotitem,hindex) =>
                    <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.name_id ? translations[selectedLanguage.code][hotspotitem?.name_id] : ' '}
                        name={hotspotitem?.name_id ? hotspotitem?.name_id : "component_hotspot_name_"+hindex}
                        characterLimit={250}
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],name:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);

                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.name_id, e.target.value);
                        }}
                        label={"Hotspot Name "+ (hindex + 1)}
                        placeholder={"Hotspot Name"}
                        error={""}
                        tooltipText={
                        "Hotspot name that is displayed when the hotspot icon is clicked."
                        }
                    />
                   <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.content_id ? translations[selectedLanguage.code][hotspotitem?.content_id] : ' '}
                        name={hotspotitem?.content_id ? hotspotitem?.content_id : "component_hotspot_content_"+hindex}
                        type="textarea"
                        characterLimit={250}
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],content:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);

                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.content_id, e.target.value);
                        }}
                        label={"Hotspot Content "+ (hindex + 1)}
                        placeholder={"Hotspot Content"}
                        error={""}
                        tooltipText={
                        "Hotspot content that is displayed when the hotspot icon is clicked."
                        }
                   />
                   <br />
                   </>
               )}

             
              </div>
            )}




            </>
        )}


  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(ChaptersForm);