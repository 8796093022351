import React, { useEffect, useState } from "react";

import classes from "./cardSort.module.scss";
import { calcResults, Column, MovableItem } from "./helpers";
import { CustomDragLayer } from "./customLayer";
import { Portal } from "react-portal";
import cn from "classnames";

import { useDragLayer } from "react-dnd";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import Lottie from "react-lottie";
import CheckAnimation from "assets/animations/Check-small-v3.json";

const CardSort = ({ question, interactive, setFooterVisibilityReq }) => {
  const [items, setItems] = useState([]);
  const [animatedColumn, setAnimatedColumn] = useState(false);
  const [wrongCount, setWrongCount] = useState(0);

  const [isEnd, setIsEnd] = useState(false);

  const [results, setResults] = useState(null);

  const filteredItems = items?.filter(
    (card) => card.column === "START" || card.column === "ANIMATE"
  );

  useEffect(() => {
    if (!isNil(question?.cards)) {
      setItems(
        question?.cards.map((quest) => {
          return {
            ...quest,
            categories: quest.categories.map((cat) => {
              return {
                ...cat,
                answer: quest.answer,
              };
            }),
          };
        })
      );
    }
  }, [question.cards]);

  useEffect(() => {
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  useEffect(() => {
    if (isEnd) {
      setFooterVisibilityReq({ isFooterVisible: true });
    }
  }, [isEnd]);

  const moveCardHandler = async (dragIndex, hoverIndex) => {
    // await delay(1000);
    const dragItem = items[dragIndex];

    if (dragItem) {
      setItems((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };

  const { isDragging, dragItem, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      dragItem: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const returnItemsForColumn = (columnName, categoryIndex, isCategory) => {
    const vals = items
      .filter((item) => item.column === columnName || item.column === "ANIMATE")
      .map((item, index) => (
        <MovableItem
          onDrag={isDragging && item.name === dragItem?.name}
          total={items?.length}
          items={items}
          key={item.id}
          name={item.name}
          currentColumnName={item.column}
          setItems={setItems}
          setAnimatedColumn={setAnimatedColumn}
          index={index}
          moveCardHandler={moveCardHandler}
          title={item.name}
          title_id={item.name_id}
          description={item.content}
          description_id={item.content_id}
          icon={item.icom}
          feedback={item.feedback}
          answer={item.answer}
          selectedAnswer={item.selectedAnswer}
          isCategory={isCategory}
          categoryIndex={categoryIndex}
          cIndex={item.catIndex}
          wrongCount={wrongCount}
          setWrongCount={setWrongCount}
          isDrop={animatedColumn}
        />
      ));
    return vals;
  };

  useEffect(() => {
    if (
      items &&
      items.length > 0 &&
      items.every((item) => item.column !== "START") &&
      items.filter((item) => item.column === "ANIMATE")?.length === 0
    ) {
      const numberOfCorrect = calcResults(items, question.categories);
      const numberOfFalse = items.length - numberOfCorrect;
      setResults({
        correctAnswers: numberOfCorrect,
        inCorrectAnswers: numberOfFalse,
      });
      setIsEnd(true);
    }
  }, [items]);

  return (
    <Portal>
      <div className={cn(classes.Container, classes.portal, classes.animation)}>
        <CustomDragLayer
          isDragging={isDragging}
          item={dragItem}
          initialOffset={initialOffset}
          currentOffset={currentOffset}
        />

        {!isEnd && (
          <div className={classes.Content}>
            <FormattedMessage
              defaultMessage={question.content}
              id={question.content_id}
            />
          </div>
        )}

        {!isEnd && (
          <Column
            title={"START"}
            className="column start"
            contentEditable={false}
            data-text={"That was the last card"}
            dataId="start"
            isDrop={animatedColumn}
          >
            <>{returnItemsForColumn("START")}</>
          </Column>
        )}
        {isEnd && (
          <>
            <div className={classes.modalBackground} />
            <div className={classes.modalLayout}>
              <h1>
                {" "}
                <FormattedMessage
                  defaultMessage={question.success_modal_title}
                  id={question.success_modal_title_id}
                />
              </h1>
              <div className={classes.AnimationWrapper}>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: false,
                    animationData: CheckAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                      progressiveLoad: true,
                    },
                  }}
                  width={"60%"}
                  isClickToPauseDisabled={true}
                  isPaused={false}
                  title={"animation"}
                  ariaLabel={"animation"}
                />
              </div>
              <span>
                <FormattedMessage
                  defaultMessage={question.success_modal_description}
                  id={question.success_modal_description_id}
                />
              </span>
            </div>
          </>
        )}
        {!isEnd && (
          <>
            <div className={classes.Category}>
              <FormattedMessage
                id={"cardsort.category.title"}
                defaultMessage={"Category to choose from"}
              />
            </div>
            <div className={classes.AnswerContainer}>
              {filteredItems &&
                filteredItems[filteredItems.length - 1]?.categories.map(
                  (category, index) => {
                    const { name, name_id, id, answer } = category;
                    return (
                      <div className={classes.columnWrapper}>
                        <Column
                          index={index}
                          id={id}
                          answer={answer}
                          title={name}
                          titleId={name_id}
                          animatedColumn={animatedColumn}
                          className={`column answer-${id}-column`}
                          categoryIndex={index}
                        >
                          {returnItemsForColumn(id, index, true)}
                        </Column>
                      </div>
                    );
                  }
                )}
            </div>
          </>
        )}
      </div>
    </Portal>
  );
};
export default CardSort;
