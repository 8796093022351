/* AUTH */
export const LOGIN_REQUEST = "AUTH/LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "AUTH/LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "AUTH/LOGIN_REQUEST_ERROR";

export const GET_ME_REQUEST = "AUTH/GET_ME_REQUEST";
export const GET_ME_REQUEST_SUCCESS = "AUTH/GET_ME_REQUEST_SUCCESS";
export const GET_ME_REQUEST_ERROR = "AUTH/GET_ME_REQUEST_ERROR";

export const SET_ME_REQUEST = "AUTH/SET_ME_REQUEST";
export const SET_ME_REQUEST_SUCCESS = "AUTH/SET_ME_REQUEST_SUCCESS";
export const SET_ME_REQUEST_ERROR = "AUTH/SET_ME_REQUEST_ERROR";

export const SUPERVISOR_LOGOUT = "AUTH/SUPERVISOR_LOGOUT";

export const SUPERVISOR_LOGIN_REQUEST = "AUTH/SUPERVISOR_LOGIN_REQUEST";
export const SUPERVISOR_LOGIN_REQUEST_SUCCESS =
  "AUTH/SUPERVISOR_LOGIN_REQUEST_SUCCESS";
export const SUPERVISOR_LOGIN_REQUEST_ERROR =
  "AUTH/SUPERVISOR_LOGIN_REQUEST_ERROR";

export const SUPERVISOR_NORMAL_LOGIN_REQUEST =
  "AUTH/SUPERVISOR_NORMAL_LOGIN_REQUEST";
export const SUPERVISOR_NORMAL_LOGIN_REQUEST_SUCCESS =
  "AUTH/SUPERVISOR_NORMAL_LOGIN_REQUEST_SUCCESS";
export const SUPERVISOR_NORMAL_LOGIN_REQUEST_ERROR =
  "AUTH/SUPERVISOR_NORMAL_LOGIN_REQUEST_ERROR";

export const STAFF_VERIFY_REQUEST = "AUTH/STAFF_VERIFY_REQUEST";
export const STAFF_VERIFY_REQUEST_SUCCESS = "AUTH/STAFF_VERIFY_REQUEST_SUCCESS";
export const STAFF_VERIFY_REQUEST_ERROR = "AUTH/STAFF_VERIFY_REQUEST_ERROR";

export const CHECK_IN_REQUEST = "AUTH/CHECK_IN_REQUEST";
export const CHECK_IN_REQUEST_SUCCESS = "AUTH/CHECK_IN_REQUEST_SUCCESS";
export const CHECK_IN_REQUEST_ERROR = "AUTH/CHECK_IN_REQUEST_ERROR";

export const CHECK_OUT_REQUEST = "AUTH/CHECK_OUT_REQUEST";
export const CHECK_OUT_REQUEST_SUCCESS = "AUTH/CHECK_OUT_REQUEST_SUCCESS";
export const CHECK_OUT_REQUEST_ERROR = "AUTH/CHECK_OUT_REQUEST_ERROR";

export const POST_LOGIN_REQUEST = "AUTH/POST_LOGIN_REQUEST";
export const POST_LOGIN_REQUEST_SUCCESS = "AUTH/POST_LOGIN_REQUEST_SUCCESS";
export const POST_LOGIN_REQUEST_FAIL = "AUTH/POST_LOGIN_REQUEST_FAIL";
export const POST_LOGIN_REQUEST_ERROR = "AUTH/POST_LOGIN_REQUEST_ERROR";

export const SITE_INDUCTION_REQUEST = "AUTH/SITE_INDUCTION_REQUEST";
export const SITE_INDUCTION_REQUEST_SUCCESS =
  "AUTH/SITE_INDUCTION_REQUEST_SUCCESS";
export const SITE_INDUCTION_REQUEST_ERROR = "AUTH/SITE_INDUCTION_REQUEST_ERROR";

export const GET_COVID_QUESTION_REQUEST = "AUTH/GET_COVID_QUESTION_REQUEST";
export const GET_COVID_QUESTION_REQUEST_SUCCESS =
  "AUTH/GET_COVID_QUESTION_REQUEST_SUCCESS";
export const GET_COVID_QUESTION_REQUEST_ERROR =
  "AUTH/GET_COVID_QUESTION_REQUEST_ERROR";

export const SET_COVID_QUESTION_REQUEST = "AUTH/SET_COVID_QUESTION_REQUEST";
export const SET_COVID_QUESTION_REQUEST_SUCCESS =
  "AUTH/SET_COVID_QUESTION_REQUEST_SUCCESS";
export const SET_COVID_QUESTION_REQUEST_ERROR =
  "AUTH/SET_COVID_QUESTION_REQUEST_ERROR";

export const GET_SITE_HAZARDS = "AUTH/GET_SITE_HAZARDS";
export const GET_SITE_HAZARDS_SUCCESS = "AUTH/GET_SITE_HAZARDS_SUCCESS";
export const GET_SITE_HAZARDS_ERROR = "AUTH/GET_SITE_HAZARDS_ERROR";

export const RESET_ERROR = "AUTH/RESET_ERROR";
export const REMOVE_TOKEN = "AUTH/REMOVE_TOKEN";
export const RESET_SITE_HAZARDS = "AUTH/RESET_SITE_HAZARDS";

export const TINCAN_LOGIN_REQUEST = "AUTH/TINCAN_LOGIN_REQUEST";
export const TINCAN_LOGIN_REQUEST_SUCCESS = "AUTH/TINCAN_LOGIN_REQUEST_SUCCESS";
export const TINCAN_LOGIN_REQUEST_ERROR = "AUTH/TINCAN_LOGIN_REQUEST_ERROR";

export const PREQUAL_REQUEST = "AUTH/PREQUAL_REQUEST";
export const PREQUAL_REQUEST_SUCCESS = "AUTH/PREQUAL_REQUEST_SUCCESS";
export const PREQUAL_REQUEST_ERROR = "AUTH/PREQUAL_REQUEST_ERROR";