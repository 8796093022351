import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import classes from "./section.module.scss";

import { makeSelectProgress } from "store/progress/selector";
import { makeSelectAssesmentState } from "store/assesment/selector";
import {
  makeSelectIsFooterVisible,
  makeSelectChapterCompletion,
} from "store/induction/selector";

import { setProgressRequest, getProgressRequest } from "store/progress/actions";
import { setAssesmentState } from "store/assesment/actions";
import {
  setFooterVisibility,
  setChapterCompletionReq,
} from "store/induction/actions";

import Footer from "components/UI/footer";
import Button from "components/UI/button";
import Heading from "components/UI/heading";
import IconComponent from "components/UI/IconComponent";
// import ChapterCompletion from "components/UI/chapterCompletion";

import { findIndex, isNil, isEmpty } from "lodash";
import { getComponent } from "./componentHandler";
import { progressHandler } from "./progressHandler";
import { motion } from "framer-motion";
import { delay } from "utils/helper";
import { useLocate, usePrevious } from "utils/hooks";
import BlockModal from "../assessment/modal";

import ChapterCompletion from "./chapterCompletion";

const Section = ({
  data,
  step,
  setStep,
  setSection,
  ruleSet,
  history,
  induction,
  setProgressReq,
  getProgressReq,
  assesmentState,
  setAssesmentStateReq,
  setFooterVisibilityReq,
  isFooterVisible,
  chapterCompletion,
  setChapterCompletion,
}) => {
  const { next, index } = useLocate(induction, history?.location?.pathname);
  const sectionData = data?.sections;

  const selectedSection = sectionData[step - 1];
  const maxStep = sectionData?.length;

  const nextStep = sectionData[step];
  const prevStep = sectionData[step - 2];

  const components = selectedSection?.data?.components;

  const indexRule = findIndex(ruleSet, data);

  const [currentProgress, setCurrentProgress] = useState(0);

  const progressRatePerStep = 100 / sectionData?.length;

  const [route, setRoute] = useState("next");
  const [load, setLoad] = useState(false);
  const [assesmentBlockModal, setAssesmentBlockModal] = useState(false);

  const previousData = usePrevious(data);

  useEffect(() => {
    if (previousData !== data) {
      setStep(1);
      setChapterCompletion(false);
    }
  }, [data]);

  useEffect(() => {
    onPass("next");
  }, []);

  const onPass = async (way) => {
    await delay(100);
    setFooterVisibilityReq({ isFooterVisible: true });
    setLoad(false);
    setRoute(way);
    await delay(100);
    setLoad(true);
    getProgressReq({ induction_id: induction.id });
  };

  const FooterAction = (isFooterVisible) => {
    return (
      <>
        {!isEmpty(components) && components[0]?.type === "assesment" ? (
          <>
            {!isEmpty(components) &&
            components[0]?.type === "assesment" &&
            isNil(assesmentState?.completed) &&
            !isNil(assesmentState?.visible) ? (
              <Footer isHidden={isFooterVisible === false}>
                <Button
                  subText={""}
                  type={""}
                  theme={"blue"}
                  id={"sendButton"}
                  // className={classes.sendButton}
                  text={
                    assesmentState?.buttonText
                      ? assesmentState.buttonText
                      : "Submit Your Answer"
                  }
                  onClick={() => {
                    setAssesmentStateReq({
                      ...assesmentState,
                      trigger: true,
                    });
                  }}
                />
              </Footer>
            ) : (
              assesmentState?.completed === true && (
                <>
                  <Footer isHidden={isFooterVisible === false}>
                    <Button
                      onClick={() => {
                        onPass("next");
                        progressHandler(
                          ruleSet,
                          indexRule,
                          maxStep,
                          setStep,
                          setSection,
                          setProgressReq,
                          data,
                          currentProgress,
                          setCurrentProgress,
                          induction,
                          history,
                          step,
                          progressRatePerStep,
                          next,
                          setChapterCompletion,
                          chapterCompletion
                        );
                      }}
                      subText={!isNil(nextStep) ? nextStep?.title : ""}
                      type={"submit"}
                      theme={"blue"}
                      id="apply-button"
                      text={
                        selectedSection?.cta_text
                          ? selectedSection?.cta_text
                          : "Next"
                      }
                      formatID={
                        selectedSection?.cta_text_id
                          ? selectedSection?.cta_text_id
                          : "next.button"
                      }
                    />
                  </Footer>
                </>
              )
            )}
          </>
        ) : (
          <>
            <Footer isHidden={isFooterVisible === false}>
              <Button
                onClick={() => {
                  
                  if(chapterCompletion)
                  {
                     //console.log('rule ended',indexRule);
                     history.push('/rules?id=' + (indexRule+1), { shallow: true });
                  }
                     

                  onPass("next");
                  progressHandler(
                    ruleSet,
                    indexRule,
                    maxStep,
                    setStep,
                    setSection,
                    setProgressReq,
                    data,
                    currentProgress,
                    setCurrentProgress,
                    induction,
                    history,
                    step,
                    progressRatePerStep,
                    next,
                    setChapterCompletion,
                    chapterCompletion
                  );
                }}
                subText={!isNil(nextStep) ? nextStep?.title : ""}
                type={"submit"}
                theme={"blue"}
                id="apply-button"
                text={
                  selectedSection?.cta_text ? selectedSection?.cta_text : "Next"
                }
                formatID={
                  selectedSection?.cta_text_id
                    ? selectedSection?.cta_text_id
                    : "next.button"
                }
              />{" "}
            </Footer>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {ruleSet[indexRule] &&
        !chapterCompletion &&
        !isEmpty(components) &&
        components[0]?.type !== "hotspots" && (
          <div
            className={classes.ruleIconRight}
            style={{
              background: ruleSet[indexRule]?.iconBackground
                ? ruleSet[indexRule]?.iconBackground
                : "#ccc",
            }}
          >
            <IconComponent
              size={"100%"}
              icon={ruleSet[indexRule]?.iconType}
              color={
                ruleSet[indexRule]?.iconColor
                  ? ruleSet[indexRule]?.iconColor
                  : "white"
              }
            />
          </div>
        )}

      {!isEmpty(components) &&
        components[0]?.type !== "hotspots" &&
        !chapterCompletion && (
          <Heading
            title={
              selectedSection?.title ? selectedSection?.title : data?.title
            }
            titleId={
              selectedSection?.title_id
                ? selectedSection?.title_id
                : data?.title_id
            }
            description={
              selectedSection?.data?.subtitle
                ? selectedSection?.data?.subtitle
                : " "
            }
            descriptionId={
              selectedSection?.data?.subtitle_id
                ? selectedSection?.data?.subtitle_id
                : "_"
            }
            backArrow={true}
            animation={
              selectedSection?.header_type == "collapsingPane" ? true : false
            }
            onBackPress={() => {
              if (!isEmpty(components) && components[0]?.type === "assesment") {
                setAssesmentBlockModal(true);
              } else {
                onPass("back");
                if (step === 1) {
                  setSection(false);
                  setStep(1);
                } else {
                  setStep(step - 1);
                }
              }
            }}
          />
        )}
      {load === true && !chapterCompletion ? (
        <motion.div
          style={{
            width: "100%",
            // paddingBottom:
            //   components?.filter((item) => item.type === "image_text_full")
            //     .length === 0 && "100px",
          }}
          initial={{ x: route === "next" ? "50%" : "-50%" }}
          animate={{
            x: "0",
          }}
          transition={{ type: "spring" }}
        >
          <div>
            {components?.map((item) =>
              getComponent(item.type, item, setFooterVisibilityReq)
            )}
          </div>
        </motion.div>
      ) : (
        load === true &&
        chapterCompletion && (
          <motion.div
            style={{
              width: "100%",
              heigth: "100%",
            }}
            initial={{ y: "100%" }}
            animate={{
              y: "0",
            }}
            transition={{ type: "spring" }}
          >
            <ChapterCompletion
              index={indexRule}
              ruleSet={ruleSet}
              data={data}
            />
          </motion.div>
        )
      )}
      {load && FooterAction(isFooterVisible)}
      <BlockModal
        visible={assesmentBlockModal}
        onCancel={() => setAssesmentBlockModal(false)}
        onConfirm={() => {
          setAssesmentBlockModal(false);
          onPass("back");
          if (step === 1) {
            setSection(false);
            setStep(1);
          } else {
            setStep(step - 1);
          }
        }}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  assesmentState: makeSelectAssesmentState(),
  isFooterVisible: makeSelectIsFooterVisible(),
  chapterCompletion: makeSelectChapterCompletion(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    getProgressReq: (val) => dispatch(getProgressRequest(val)),
    setAssesmentStateReq: (val) => dispatch(setAssesmentState(val)),
    setFooterVisibilityReq: (val) => dispatch(setFooterVisibility(val)),
    setChapterCompletion: (val) =>
      dispatch(setChapterCompletionReq({ value: val })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
