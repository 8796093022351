import React, { useEffect, useState } from "react";
import classes from "./vrCards.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { format, parseISO } from "date-fns";
import { isEmpty, isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";

import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Loading from "components/UI/loading";

import TickIcon from "../../../pages/LookAround/tick.svg";

import { getMeRequest, setMeRequest } from "../../../store/auth/actions";
import {
  makeSelectMe,
  makeSelectLoading as makeSelectMeLoading,
} from "../../../store/auth/selector";

import { makeSelectSelectedLanguage } from "store/language/selector";

const VRCards = ({
  interactive,
  setFooterVisibilityReq,
  vrcardsdata,
  selectedLanguage,
  loading,
  getMe,
  setMe,
  me,
  sendVrData,
}) => {
  const [vrStatus, setVrStatus] = useState();
  const [vrData, setVrData] = useState([]);

  useEffect(() => {
    /*
    //get if there is something on query string
    const parsed = queryString.parse(window.location.search);

    var completedItems = JSON.parse(parsed.completed);

    if(completedItems.length > 0)
    {
      const statusData = vrcardsdata?.vr?.map((item, index) => {
        return {
          id: item?.id ? item?.id : index,
          completed: completedItems.includes(item?.id) ? true : false,
        };
      });
      
      setMe({
        vrstatus: JSON.stringify(statusData),
        language: selectedLanguage?.code
      });
    }
    */

    //check if all is completed and if yes, enable the next button
    //don't enable if the data is not loaded yet
    if(vrData.filter((item) => item.completed).length == vrData.length && vrData.length > 0)
    {
      //console.log('show footer');
      setFooterVisibilityReq({ isFooterVisible: true });
    }

    getMe();

  }, []);

  useEffect(() => {
    
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  useEffect(() => {
    if (!isNil(me)) 
    {
      setVrStatus(me?.vrstatus);
      var statusData = {}

      var cannotParse = false;
      try {
        const a = JSON.parse(me?.vrstatus);

        if(a.length == 0)
          cannotParse = true;

      } catch (e) {
        cannotParse = true;
      }

      if (isNil(me?.vrstatus) || cannotParse) 
      {
          statusData = vrcardsdata?.vr?.map((item, index) => {
            return {
              id: item?.id ? item?.id : index,
              completed: false,
            };
          });
  
          setVrData(statusData);
          sendVrData(statusData); //send to the page
      } 
      else 
      {
        const parsedStatus = JSON.parse(me?.vrstatus);

        //console.log('parsedStatus',parsedStatus);
        //console.log('parsedStatus2',[...parsedStatus,{id:100,completed:true}]);

        //There can be some data from other inductions so don't overwrite those
        var notIncluded = [];

        parsedStatus.map((item, index) => {

          if(vrcardsdata?.vr?.filter((statusitem) => statusitem?.id == item?.id).length == 0)
            notIncluded.push(item);

        });

        console.log('notIncluded',notIncluded);

        statusData = vrcardsdata?.vr?.map((item, index) => {
          return {
            id: item?.id ? item?.id : index,
            completed: parsedStatus.filter(
              (statusitem) => statusitem?.id == item?.id
            )[0]?.completed ? true : false,
          };
        }).concat(notIncluded);
        
        console.log('statusData',statusData);
        setVrData(statusData);
        sendVrData(statusData);
      }

      //now check if there is a completed url param and if there is, set those items as completed
      const parsed = queryString.parse(window.location.search);

      var completedItems = [];
      
      if(parsed.completed)
         completedItems = JSON.parse(parsed.completed);
      
      console.log('completedItems',completedItems);
      if(completedItems.length > 0)
      {
        const completedData = statusData.map((item, index) => {
          return {
            id: item?.id ? item?.id : index,
            completed: completedItems.includes(item?.id) ? true : item?.completed,
          };
        });
        
        console.log('completedData',completedData);
        if(completedData.length > 0)
        {
          
          setMe({
            vrstatus: JSON.stringify(completedData),
            language: selectedLanguage?.code
          });
          
          //just make sure it's visible
          //setFooterVisibilityReq({ isFooterVisible: true });
          
          //console.log('data to send',completedData);

          if(completedData.filter((item) => item.completed).length == completedData.length && completedData.length > 0)
          {
              console.log('show footer - after setMe');
              setTimeout(function(){ setFooterVisibilityReq({ isFooterVisible: true }); },1500); //just to be safe for race condition here
              setFooterVisibilityReq({ isFooterVisible: true });
          }
        }
        
      }      

      //check if all is completed and if yes, enable the next button
      //don't enable if the data is not loaded yet
      if(vrData.filter((item) => item.completed).length == vrData.length && vrData.length > 0)
      {
        console.log('show footer');
        setTimeout(function(){ setFooterVisibilityReq({ isFooterVisible: true }); },1500); //just to be safe for race condition here
        setFooterVisibilityReq({ isFooterVisible: true });
      }


    }
  }, [me, vrcardsdata]);

  if (loading && isEmpty(vrData)) {
    return (
      <div className={classes.loadingWrapper}>
        {" "}
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className={classes.Container}>
        <div className={classes.vrWrapper}>
          {vrcardsdata?.vr.map((item, index) => (
            <>
              <div className={classes.VRContainer}>
                {vrData.filter((statusitem) => statusitem?.id == item?.id)[0]?.completed && (
                  <div className={classes.completedWrapper}>
                    <img src={TickIcon} />
                  </div>
                )}
                <img
                  alt="VR Experience 1"
                  className={classes.Image}
                  src={item.image}
                />
              </div>
              <div className={classes.ButtonContainer}>
                <Button
                  borderRadius={"6px"}
                  height={"44px"}
                  text={item?.button_name}
                  theme="blue"
                  formatID={
                    item?.button_id
                      ? item?.button_id
                      : "take-a-look." + index + ".text"
                  }
                  onClick={() => {
                    const newVRData = vrData.map((a, i) => {
                      return {
                        ...a,
                        completed: i === index ? true : a.completed,
                      };
                    });
                    setMe({
                      vrstatus: JSON.stringify(newVRData),
                      language: selectedLanguage?.code,
                      callBack: () => {
                        return (window.location = item.url);
                      },
                    });
                  }}
                  disabled={false}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  me: makeSelectMe(),
  loading: makeSelectMeLoading(),
  selectedLanguage: makeSelectSelectedLanguage(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMe: (val) => dispatch(getMeRequest(val)),
    setMe: (val) => dispatch(setMeRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VRCards);
