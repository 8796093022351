import React, { useState, useEffect } from "react";
import classes from "./loading.module.scss";
import MercuryLogo from "./mercury.svg";
import Portal from "components/Supervisor/portal";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <Portal>
      <div className={classes.Container}>
        <div className={classes.logoWrapper}>
          <img src={MercuryLogo} alt="logo" />
          <ReactLoading
            width={"40px"}
            height={"40px"}
            color={"#000000"}
            type={"spin"}
          />
        </div>
      </div>
    </Portal>
  );
};

export default Loading;
