import React, { useEffect, useState } from "react";
import classes from "./siteCard.module.scss";
import TickIcon from "./tick.svg";
import { format, parseISO } from "date-fns";
import { isNil } from "lodash";
import TimeIcon from "./time.svg";
import TimeWhiteIcon from "./time-white.svg";
import { FormattedMessage } from "react-intl";

import cn from "classnames";
import Site from "pages/Supervisor/Site";

const formatDate = (val) =>
  !isNil(val) ? format(parseISO(val), `MM/dd/yyyy`) : "-";

const SiteCard = ({
  title,
  titleId,
  selected,
  key,
  onClick,
  image,
  type,
  status,
  description,
  descriptionId,
}) => {
  const [renewalDayCount, setRenewalDayCount] = useState(null);
  useEffect(() => {
    if (status?.renewal) {
      const date = new Date();
      var difference = Math.round(
        new Date(status.renewal).getTime() - date.getTime()
      );
      var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    }
    setRenewalDayCount(daysDifference);
  }, [status]);

  return (
    <div
      // style={{
      //   borderColor: type.color,
      // }}
      onClick={onClick}
      key={key}
      className={
        selected ? classes.cardContainerSelected : classes.cardContainer
      }
    >
      <div className={classes.checkContainer}>
        {image ? (
          <div
            className={classes.image}
            style={{
              background: `url(${image}) no-repeat center`,
              backgroundSize: "cover",
            }}
          />
        ) : (
          // <img alt={title} className={classes.image} src={image} />
          <div
            // style={{ backgroundColor: type.color }}
            className={selected ? classes.check : classes.unCheck}
          >
            {selected && <img alt={"Tick Icon"} src={TickIcon} />}
          </div>
        )}

        <div className={classes.textWrapper}>
          <span className={classes.title}>
            <FormattedMessage id={titleId} defaultMessage={title} />
          </span>
          <span className={classes.description}>
            <FormattedMessage id={descriptionId} defaultMessage={description} />
          </span>
        </div>
        {status?.renewal && (
          <div className={cn(classes.renewal, classes.renewalWithImage)}>
            <span>
              <FormattedMessage
                id={"site.selector.renewal"}
                defaultMessage={"Renewal"}
              />
              : {formatDate(status.renewal)} • {renewalDayCount}{" "}
              <FormattedMessage
                id={"site.selector.days"}
                defaultMessage={"days"}
              />
            </span>
          </div>
        )}
      </div>
      <div
        style={{ backgroundColor: selected ? "#FFC719" : type.color }}
        className={selected ? classes.timerSelected : classes.timer}
      >
        {type.info.text && (
          <>
            <span
              className={cn(
                classes.timeText,
                type.info.text === "Completed" && classes.white,
                selected && classes.white
              )}
            >
              <FormattedMessage
                id={type.info.formatId}
                defaultMessage={type.info.text}
              />
            </span>
          </>
        )}
        {type.info.time && (
          <>
            <img
              alt={"time icon"}
              src={selected ? TimeWhiteIcon : TimeIcon}
              className={classes.timeIcon}
            />
            <span className={cn(classes.timeText, selected && classes.white)}>
              7 min
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default SiteCard;
