import React from "react";
import { useIntl } from "react-intl";

const FormattedHTMLMessage = ({ id, value, items = {} }) => {
  const intl = useIntl();
  const translated = intl.formatMessage(
    { id: id },
    { value: value, ...items },
    { defaultValue: value }
  );
  return <div dangerouslySetInnerHTML={{ __html: translated }} />;
};

export default FormattedHTMLMessage;
