import React, { useEffect } from "react";
import classes from "./alreadycompleted.module.scss";

import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Heading from "components/UI/heading";
import Lottie from "react-lottie";

import CelebrateAnimation from "assets/animations/Mercury_celebration-once.json";

const AlreadyCompleted = ({ isPreview = false }) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className={classes.AllChaptersCompleteContainer}>
        <Heading
          backArrow={false}
          title={"You Have Completed This Site Induction Already"}
          titleId={"alreadycompleted.title"}
          description={"You can proceed to sign-in"}
          descriptionId={"alreadycompleted.description"}
        />
        &nbsp;
        <div className={classes.animationWrapper}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: CelebrateAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
                progressiveLoad: true,
              },
            }}
            width={"40%"}
            isClickToPauseDisabled={true}
            isPaused={false}
            title={"animation"}
            ariaLabel={"animation"}
          />
        </div>
        {/* <img
          className={classes.completeImage}
          alt="all chapters complete image"
          src={"/chaptersComplete.png"}
        /> */}
        <div className={classes.ContentContainer}>
          <p>
            <FormattedHTMLMessage
              defaultMessage={"Remember"}
              id={"all-chapters-complete.content"}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default AlreadyCompleted;
