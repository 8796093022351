import Button from "components/UI/button";
import React, { useEffect, useState } from "react";
import classes from "./hotspotModal.module.scss";
import { ReactComponent as CloseIcon } from "./close.svg";

import Lottie from "react-lottie";

//animations
import CheckAnimation from "assets/animations/Check-small-v3.json";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import { FormattedMessage } from "react-intl";

const HotSpotModal = ({
  onClose,
  name,
  name_id,
  animationName,
  content,
  content_id,
  content_image,
  type,
  isVisible,
  buttonLabel,
  button_id,
  closeIconVisible,
}) => {
  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    if (animationName != "") {
      setIsPaused(true);
      setTimeout(() => {
        setIsPaused(false);
      }, 1000);
    }
  }, [animationName]);

  return (
    <div className={isVisible ? classes.ModalVisible : classes.ModalHidden}>
      <div
        className={
          type == "Intro"
            ? classes.ModalWrapperIntro
            : type == "Outro"
            ? classes.ModalWrapper
            : classes.ModalWrapperHotspot
        }
      >
        <div
          className={
            type == "Intro" || type == "Outro"
              ? classes.Modal
              : classes.ModalHotspot
          }
        >
          {closeIconVisible && (
            <div
              className={classes.closeIconWrapper}
              onClick={() => {
                onClose(name);
              }}
            >
              <CloseIcon />
            </div>
          )}

          <div className={classes.TitleWrapper}>
            <FormattedMessage
              id={name_id ? name_id : name ? name : "default"}
              defaultMessage={name}
            />
          </div>

          {animationName == "check" && (
            <div className={classes.AnimationWrapper}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: false,
                  animationData: CheckAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                    progressiveLoad: true,
                  },
                }}
                width={"60%"}
                isClickToPauseDisabled={true}
                isPaused={isPaused}
                title={"animation"}
                ariaLabel={"animation"}
              />
            </div>
          )}
          <div
            className={classes.ContentWrapper}
            // dangerouslySetInnerHTML={{ __html: content }}
          >
            {content_image && (
              <img src={content_image} className={classes.contentImage} />
            )}
            <FormattedHTMLMessage
              id={content_id ? content_id : content ? content : "default"}
              value={content}
            />
          </div>
          <div className={classes.ButtonWrapper}>
            {buttonLabel && (
              <Button
                borderRadius="22px"
                type={"next"}
                theme={"blue"}
                id="apply-button"
                text={buttonLabel}
                onClick={() => {
                  onClose(name);
                }}
                formatID={button_id ? button_id : "default"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotSpotModal;
